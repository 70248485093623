<template>
  <div>
    <card-component
      v-if="!passwordRecovery"
      :title="$t('login')"
      icon="lock"
      :has-card-header-background="true"
      :has-button-slot="true"
    >
      <lang-bar slot="button" />
      <form method="POST" @submit.prevent="submit">
        <!--<b-field :label="$t('company')">
          <b-input
            v-model="company"
            name="company"
            type="text"
            required
            :autofocus="company.length === 0"
          />
        </b-field>-->
        <b-field :label="$t('username')">
          <b-input
            v-model="username"
            name="username"
            type="text"
            placeholder="Lütfen kullanıcı adınızı giriniz. Mail adresi girilmemelidir."
            validation-message="Lütfen bu alana kullanıcı adınızı giriniz."
            required
            :autofocus="company.length > 0"
          />
        </b-field>
        <b-field :label="$t('password')">
          <b-input
            ref="password"
            v-model="password"
            type="password"
            name="password"
            placeholder="Lütfen şifrenizi giriniz."
            validation-message="Lütfen bu alana şifrenizi giriniz."
            required
          />
        </b-field>

        <hr>

        <b-field>
          <b-button
            size="is-medium"
            native-type="submit"
            type="is-purble"
            :class="{'is-loading':isLoading}"
            icon-left="login-variant"
            class="is-fullwidth"
          >
            {{ $t('login') }}
          </b-button>
        </b-field>
        <b-field>
          <b-button
            type="is-text"
            class="is-pulled-right"
            @click="passwordRecovery = true"
          >
            {{ $t('forgotPassword') }}
          </b-button>
        </b-field>
        <div class="is-clearfix" />
      </form>
    </card-component>

    <card-component
      v-if="passwordRecovery"
      :title="$t('passwordRecovery')"
      icon="lock-open"
      :has-card-header-background="true"
      :has-button-slot="true"
    >
      <lang-bar slot="button" />
      <form method="POST" @submit.prevent="submitRecovery">
        <!--<b-field :label="$t('company')">
          <b-input
            v-model="company"
            type="text"
            required
            autofocus
          />
        </b-field>-->
        <b-field :label="$t('emailAddress')">
          <b-input
            v-model="email"
            type="email"
            validation-message="Lütfen bu alana e-posta adresinizi giriniz."
            required
          />
        </b-field>
        <hr>
        <b-field grouped>
          <div class="control">
            <b-button
              type="is-black"
              native-type="submit"
              :class="{'is-loading':isLoading}"
            >
              {{ $t('sendResetLink') }}
            </b-button>
          </div>
          <div class="control">
            <b-button
              type="is-black"
              outlined
              @click="passwordRecovery = false"
            >
              {{ $t('login') }}
            </b-button>
          </div>
        </b-field>
      </form>
    </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import LangBar from '@/components/LangBar'
import axios from 'axios'

export default {
  components: {
    CardComponent,
    LangBar,
  },
  data () {
    return {
      isLoading: false,
      username: '',
      password: '',
      company: 'trt',
      email: '',
      passwordRecovery: false,
    }
  },
  created () {
    if (location.hostname === 'test-ui.bulutfilogo.com') {
      this.company = 'test'
    }
  },
  methods: {
    submit () {
      const { username, password, company } = this;
      this.isLoading = true
      this.$store.dispatch('login', {
        username,
        password,
        company,
      })
        .then(() => this.$router.push({ path: '/' }))
        .catch(() => {
          this.password = '';
          this.$buefy.toast.open({
            message: this.$t('loginFailed'),
            type: 'is-danger',
            queue: false,
            position: 'is-top'
          })
          this.$refs['password'].focus();
        })
        .finally(() => {
          this.isLoading = false
        });
    },
    submitRecovery () {
      const { email, company } = this;
      const baseURL = '//' + company + '.' + process.env.VUE_APP_MAIN_DOMAIN + '/api/';
      this.isLoading = true
      axios.post(`${baseURL}auth/recovery`, {
        email: email
        },{
        headers: {
          'X-Localization': localStorage.getItem('lang') || 'en'
        }
        })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t('recoveryLinkSend'),
            type: 'is-success',
            queue: false,
            position: 'is-top'
          })
          this.passwordRecovery = false
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t('operationFailed'),
            type: 'is-danger',
            queue: false,
            position: 'is-top'
          })
        })
        .finally(() => {
          this.isLoading = false
        });
    },
  },
}
</script>
