<template>
  <div class="WordSection1">
    <p style="margin-bottom: 6pt; text-align: center">
      <b><span
        lang="TR"
        style="font-size: 20pt; font-family: Helvetica; color: #0d67ac"
      >TRT GO KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA GİZLİLİK
        POLİTİKASI VE AYDINLATMA METNİ</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">İşbu Gizlilik Politikası ve Aydınlatma Metni’nde, Ayazağa, Çakırlar Sk.
        No:21, 34396 Sarıyer/İstanbul adresinde faaliyette bulunan </span><span lang="TR" style="font-family: Helvetica; color: #1d1d1b">Türkiye Radyo Televizyon Kurumu </span><span lang="TR" style="font-family: Helvetica">A.Ş.&nbsp;<b>(“TRT GO, Bulut Filo Go”)</b>&nbsp;tarafından işletilmekte
        olan TRT GO Mobil Uygulaması’nın&nbsp;<b>(“TRT GO, Bulut Filo Go”)</b>&nbsp;, Bulut Filo Go (“Websitesi”) işletilmesi sırasında Mobil
        Uygulama ve Websitesi Kullanıcıları (“Veri Sahibi”) tarafından Bulut
        Filo Go ile paylaşılan veya Bulut Filo Yönetimi’nin, Veri Sahibi’nin
        Mobil Uygulama’yı ve Websitesini kullanımı sırasında ürettiği kişisel
        verilerin, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve
        ilgili mevzuata uygun olarak işlenmesine ilişkin koşul ve şartlara yer
        verilmiştir. Kullanıcılara ait verilerin, güvenli şekilde barındırılması
        sağlanmakta ve olası hukuka aykırı erişimlere karşı gerekli tüm güvenlik
        tedbirlerini alınmaktadır. İşbu Gizlilik Politikası ve Aydınlatma Metni
        ile, Mobil Uygulama ve Websitesi üzerinden sunmakta olduğumuz hizmetler
        çerçevesinde toplanan kişisel verilerinizin, hangi kapsamda işlendiği
        açıklanmaktadır.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">1.Veri Sorumlusu</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Kişisel verileriniz, veri sorumlusu sıfatıyla Bulut Filo Go nezdinde
        ilgili mevzuata uygun olarak ve aşağıda belirtilen usullerde
        işlenmektedir.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">2.Kişisel Verilerinizin Toplanma Yöntemi ve İşlemenin Hukuki
        Sebepleri</span></b>
    </p>

    <p class="MsoNormal">
      <b><span lang="TR" style="font-family: Helvetica">2.1.</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;Bulut Filo Go nezdinde yürütülen ticari faaliyet kapsamında
        toplanan tüm bilgiler, Bulut Filo Go nezdinde yürütülen süreçlerde
        kullanılmak adına, Bulut Filo Go’nun faaliyetlerinin hukuka uygun olarak
        yürütülmesi için işlenmektedir. Bu faaliyetlerin kapsamında temin edilen
        kişisel veriler ve özel nitelikli kişisel veriler, sözlü, yazılı,
        elektronik ortamda temin edilmektedir. Kişisel verileriniz, Bulut Filo
        Go tarafından tamamen veya kısmen otomatik olan ya da herhangi bir veri
        kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla, Mobil
        uygulama ve Websitesi vasıtasıyla, sözleşmenin kurulması veya ifası,
        meşru menfaat, hukuki yükümlülük, bir hakkın tesisi, kullanılması veya
        korunması ve açık rıza gerektiren faaliyetler bakımından açık rıza
        hukuki sebepleri kapsamında toplanmaktadır.</span>
    </p>

    <p class="MsoNormal">
      <b><span lang="TR" style="font-family: Helvetica">2.2.</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;Aksi açıkça belirtilmedikçe, işbu Gizlilik Politikası kapsamında
        arz edilen&nbsp;<b>hüküm ve koşullar kapsamında “kişisel veri” ifadesi aşağıda yer alan
          alan bilgileri kapsayacaktır. - Kimlik ve İletişim Bilgisi: ad, soyad,
          telefon, e-posta adresi - Kullanıcı Bilgisi, Kullanıcı İşlem Bilgisi
          ve Finansal Bilgi</b>&nbsp;:Üyelik bilgileri, üyelik ID numarası, Mobil Uygulama
        hizmetlerinden yararlandığınız tarih ve saate ilişkin veriler, Mobil
        Uygulama, Websitesi veya Canlı Destek ile iletişime geçme
        nedenleriniz.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><b><span lang="TR" style="font-family: Helvetica">İşlem Güvenliği Bilgisi:</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;log-in credential bilgileri, parola bilgileri, kullanıcı
        hareketleri</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><b><span lang="TR" style="font-family: Helvetica">Lokasyon Verisi:</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;Sistemin doğru şekilde kullanımı ve güvenlik için alınan konum
        bilgileri,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><b><span lang="TR" style="font-family: Helvetica">Pazarlama Bilgisi:</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;Alışkanlıklarınız ile beğenilerinizi gösteren raporlar ve
        değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları vb.
        bilgiler</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><b><span lang="TR" style="font-family: Helvetica">Talep/Şikayet Yönetimi Bilgisi:</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;Mobil Uygulama,websitesi ve çağrı merkezi üzerinden yaptığınız
        talep ve şikayetler ile paylaştığınız yorumlarınız</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><b><span lang="TR" style="font-family: Helvetica">Risk Yönetimi Bilgisi:</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;IP adresi, Cihaz bilgisi</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal">
      <b><span lang="TR" style="font-family: Helvetica">2.3.</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;Kanunun 3. ve 7. maddeleri çerçeve anonim hale getirilen veriler,
        anılan kanun hükümleri uyarınca kişisel veri olarak kabul edilmeyecek ve
        bu verilere ilişkin işleme faaliyetleri işbu Gizlilik Politikası
        hükümleri ile bağlı olmaksızın gerçekleştirecektir.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal">
      <b><span lang="TR" style="font-family: Helvetica">2.4.</span></b><span lang="TR" style="font-family: Helvetica">&nbsp;KVKK’nın 5’inci ve 6’ncı maddelerinde belirtilen kişisel veri
        işleme şartları, ürün veya hizmetlere ilişkin kullanıcı
        bilgilendirmelerinin gerçekleştirilmesi (örn. yasal bilgilendirmeler,
        hesap hareketleri bildirimleri), muhasebe ve finans işlemleri, müşteri
        ilişkileri yönetimi, talep ve/veya şikayetlerin değerlendirilmesi ile
        çözüme kavuşturulması, Bulut Filo Go tarafından sunulan hizmetlerden
        kullanıcılarımızı faydalandırmak için gerekli çalışmaların iş
        birimlerimiz tarafından yapılması, bilgi güvenliğinin sağlanması, hesaba
        güvenli giriş işlemlerinin gerçekleştirilebilmesi, verilerin
        doğruluğunun ve kalite standartlarının sağlanması ile mevzuattan
        kaynaklı yükümlülüklerin yerine getirilmesi süreçleri kapsamında işlenen
        kişisel veri kategorileriniz ile söz konusu kişisel verilerinizin
        işlenme amaçları aşağıda detaylı şekilde açıklanmaktadır.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal">
      <b><span lang="TR" style="font-family: Helvetica">Kişisel Veri Kategorisi</span></b>
    </p>

    <p class="MsoNormal">
      <b><span lang="TR" style="font-family: Helvetica">Kişisel Veri İşleme Amaçları</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">İletişim Verisi (isim-soyisim , telefon numarası, e-posta adresi)</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Bilgi Güvenliği Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Faaliyetlerin Mevzuata Uygun Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Finans ve Muhasebe İşlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Hukuk İşlerinin Takibi Ve Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">İletişim Faaliyetlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">İş Faaliyetlerinin Yürütülmesi / Denetimi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Mal / Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Talep / Şikayetlerin Takibi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Veri Sorumlusu Operasyonlarının Güvenliğinin Temini</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Mal / Hizmet Satış Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Sözleşme Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Lokasyon Verisi (açık rızanın varlığı halinde)</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Yakın Üye İşyeri butonunun kullanılması halinde, en yakın Üye
        İşyeri’nin gösterilmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Üye İşyeri Potansiyeli taşıyan bölgelere ilişkin bilgi edinilmesi</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Finans Verisi (Bakiye yükleme ve harcama işlem bilgisi verisi) (Mobil
        uygulama ve Websitesi üzerinden bakiye yükleme amacıyla gireceğiniz
        kart/banka bilgileri işlemin tamamlanmasından sonra Şirketimizce
        muhafaza edilmemektedir.)</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Faaliyetlerin Mevzuata Uygun Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Finans ve Muhasebe İşlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Hukuk İşlerinin Takibi Ve Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Mal / Hizmet Satış Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Sözleşme Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Pazarlama Bilgisi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Alışkanlıklarınız ile beğenilerinizi gösteren raporlar ve
        değerlendirmeler, hedefleme bilgileri, çerez (cookie) kayıtları vb.
        bilgiler.</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">İşlem Güvenliği Verisi (Güvenli giriş işlemlerinin temini
        amacıyla)</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Bilgi Güvenliği Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Faaliyetlerin Mevzuata Uygun Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Yetkili Kişi, Kurum Ve Kuruluşlara Bilgi Verilmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Talep / Şikayet Verisi (Destek hattından iletişime geçildiğinde,
        sorunun çözümüne ilişkin bilgiler)</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Faaliyetlerin Mevzuata Uygun Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Talep / Şikayetlerin Takibi</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">3.Kişisel Verilerinizin Üçüncü Kişilerle Aktarılması</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Bulut Filo Go nezdinde paylaşılmış olan kişisel verilerinize, yalnızca
        yukarıda detaylarına yer verilen amaçlar bakımından sınırlı yetki
        erişimine sahip çalışanlarımız tarafından kendi görevlerini yerine
        getirmek amacıyla gerekli ölçüde erişilebilmektedir. Öte yandan toplanan
        kişisel verileriniz, KVKK’nın 8’inci ve 9’uncu maddeleri uyarınca
        kanunlarda açıkça öngörülmesi, meşru menfaat, veri sorumlusunun hukuki
        yükümlülüğü, bir hakkın tesisi, kullanılması veya korunması ve açık rıza
        gerektiren faaliyetler bakımından açık rızanızın varlığı halinde;</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Bulut Filo Go’nun faaliyetleri gereği, hizmetin yürütülmesi bakımından
        ürün ve/veya hizmet temin edilmesi amaçlarıyla; üründe yer alan mevcut
        müşterilere, ileride yer alacak olan müşterilere, yurt içinde yerleşik
        tedarikçilerimize, (örn. çağrı merkezi, mobil altyapı sağlayan bilişim
        teknolojileri ve veri barındırma hizmet sağlayıcıları),
        danışmanlarımıza, ihtilaf halinde hukuk danışmanlarımıza, grup
        şirketlerimize, alt işverenlere, program-hizmet ortağı şahıs ve
        kuruluşlara, ilgili resmi kurumlara ve istisnai olarak özel kişilere
        aktarılabilmektedir. Kişisel verilerin işlenme amacı ile verilerin
        aktarım amacı paralellik göstermektedir.</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Olası şirket birleşme, devralma, ortak girişim, bölünme ve sair yapısal
        değişikliklerde Şirketimizin hukuki ve finansal inceleme faaliyetlerinin
        gerçekleştirilmesi amaçlarıyla potansiyel alıcı, satıcı veya işlemin
        muhatabı sair taraflar ve ilgili sürece dahil olan veri tabanı hizmet
        sağlayıcılarına,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Şirketimizin hukuki yükümlüklerinin yerine getirilmesi ile hukuk
        işlerinin takibi amaçlarıyla kanunen yetkili kuruluşlara ve özel
        kişilere, aktarılabilecektir. Kişisel verileriniz, yurt içinde ve yurt
        dışında yerleşik yukarıda detayları yer alan üçüncü kişilere yine bu
        sınırlar dahilinde aktarılabilmektedir.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">4.Verilerinizin Güvenliğinin Sağlanması</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Üyelik bilgileri ve diğer kişisel verileriniz, üçüncü kişilerle, ilgili
        kanuna uygun olmayan şekilde paylaşılamaz ve bu verilerin güvenliğinden
        ilgili kanun uyarınca Bulut Filo Go sorumludur. Bulut Filo Go ilgili
        mevzuatta belirlenen veya işbu Gizlilik Politikası’nda ifade edilen
        şartlarda,</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">kişisel verilerin hukuka aykırı olarak işlenmemesini,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">kişisel verilere hukuka aykırı olarak erişilmemesini ve</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">kişisel verilerin muhafazasını sağlamak amacıyla uygun şartlar
        maliyetler gözetilerek/ asgari güvenlik düzeyini temin etmeye yönelik
        gerekli teknik ve idari tedbirleri almayı, gerekli denetimleri
        yaptırmaktadır. Bulut Filo Go ayrıca sizden elde ettiği kişisel verileri
        işbu Gizlilik Politikası ve Kişisel Verilerin Korunması Kanunu
        hükümlerine aykırı olarak başkasına açıklamamakta ve işleme amacı
        dışında kullanmamaktadır.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">5.Gizlilik Politikası’ndaki Değişiklikler</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">İşbu Gizlilik Politikası değişen şartlara ve mevzuata uyum sağlamak
        amacıyla zaman zaman güncellenebilecektir.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">6.Kişisel Verilerinizin İşlenme Süresi</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Bulut Filo Go, sistemi kullanırken sağladığınız kişisel verileri,
        üyeliğin sona ermesinden itibaren 10 sene boyunca saklar. Bulut Filo
        Go’nun Kullanıcı hesaplarının sağladığı kişisel verileri saklama
        süreleri, verilen hizmetlerin sağlanmasındaki süreklilik göz önüne
        alınarak belirlenmiştir. Süre geçtikten sonra kişisel verileriniz Bulut
        Filo Go tarafından veya talebiniz üzerine silinir, yok edilir veya
        anonim hale getirilir.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">7.KVKK Kapsamında Haklarınız</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Kişisel veri sahibi olarak KVKK’nın 11’inci maddesi uyarınca aşağıdaki
        haklara sahip olduğunuzu bildiririz:</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Kişisel verilerinizin işlenip işlenmediğini öğrenme,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
        kullanılıp kullanılmadığını öğrenme,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü
        kişileri bilme,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
        bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
        verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına
        rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde
        kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu
        kapsamda ve kişisel</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">verilerinizin eksik veya yanlış işlenmiş olması halinde yapılan
        işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere
        bildirilmesini isteme,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz
        edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına
        itiraz etme,</span>
    </p>

    <p class="MsoNormal" style="margin-left: 0.5in; text-indent: -0.5in">
      <span lang="TR" style="font-family: Helvetica">•<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span><span lang="TR" style="font-family: Helvetica">Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara
        uğraması halinde zararın giderilmesini talep etme.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Yukarıda belirtilen haklarınıza yönelik başvurularınızı, Veri
        Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ hükümlerine uygun
        şekilde yazılı olarak veya kayıtlı elektronik posta (KEP) adresi,
        güvenli elektronik imza, mobil imza veya Şirketimize daha önce bildirmiş
        olduğunuz ve sistemlerimizde kayıtlı olan e-posta adresiniz üzerinden
        elektronik olarak Şirketimizin gerçek hak sahibi olduğunuzun tespitini
        gerçekleştirebilmesi amacıyla kimliğinizi doğrulayan
        bilgilerle/belgelerle*&nbsp;birlikte&nbsp;</span><span lang="TR"><a href="mailto:info@bulutfilo.com"><span
        style="
              font-family: Helvetica;
              color: #0d67ac;
              text-decoration: none;
            "
      >info@bulutfilo.com</span></a></span><span lang="TR" style="font-family: Helvetica">&nbsp;adresinden ulaşabileceğiniz İlgili Kişi Başvuru Formu’nu
        doldurarak Bulut Filo Go’ya iletebilirsiniz. Talebinizin niteliğine göre
        en kısa sürede ve en geç 30 (otuz) gün içinde başvurularınız ücretsiz
        olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet
        gerektirmesi halinde Kişisel Verileri Koruma Kurulu tarafından
        belirlenecek tarifeye göre tarafınızdan ücret talep
        edilebilecektir.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">*Başvuru esnasında kimliğinizi doğrulamaya yarayan T.C. kimlik numarası
        veya diğer ülke vatandaşları için pasaport numarası, tebligata esas
        yerleşim yeri adresi/iş yeri adresi, cep telefonu/telefon/faks numarası,
        e-posta adresi vb. bilgilerin, belgelerle birlikte başvuru evrakına
        eklenmesi gerekmektedir. Başvuru sırasında hiçbir şekilde özel nitelikli
        kişisel veri (sağlık bilgisi, din bilgisi, etnik köken bilgisi gibi.)
        paylaşılmamasına özen göstermenizi rica ederiz.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>

    <p class="MsoNormal" style="margin-bottom: 6pt">
      <b><span lang="TR" style="font-size: 15pt; font-family: Helvetica">8.Çerez Politikası</span></b>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">Bulut Filo Go’nun kullanımı sırasında; hizmetlerimizi geliştirmek ve
        daha kaliteli ve etkin bir hizmet sunabilmek için temel hak ve
        özgürlüklerinize zarar gelmemesi gözetilerek, meşru menfaatlerimiz
        kapsamında ve bazı çerezler kullanılabilmektedir. Bu konuda detaylı
        bilgi için Bulut Filo Go çerez politikamıza&nbsp;</span><span lang="TR"><a href="https://bulutfiloyonetimi.com/politikalar/cerez-politikasi"><span
        style="
              font-family: Helvetica;
              color: #0d67ac;
              text-decoration: none;
            "
      >https://bulutfiloyonetimi.com/politikalar/cerez-politikasi</span></a></span><span lang="TR" style="font-family: Helvetica">&nbsp;linkinden ulaşabilirsiniz. Üye, Bireysel Kullanıcı Sözleşmesini
        ve işbu Gizlilik Politikası ve Aydınlatma Metni’ni kabul ettiğinde Bulut
        Filo Go’ya ait sistemlerde çerez kullanıldığını anladığını ve bundan
        dolayı Bulut Filo Go’da her ne şekilde olursa olsun herhangi bir
        tazminat talebinde bulunmayacağını kabul ve taahhüt eder. </span><span lang="TR" style="font-family: Helvetica; color: #1d1d1b">Türkiye Radyo Televizyon Kurumu.</span>
    </p>

    <p class="MsoNormal">
      <span lang="TR" style="font-family: Helvetica">&nbsp;</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  methods: {},
};
</script>

<style>
@media screen and (min-width: 769px), print {
  .column.is-two-fifths {
    width: 70%;
  }
}
</style>