<template>
  <card-component
    :title="$t('passwordRecovery')"
    icon="lock-open"
    :has-card-header-background="true"
    :has-button-slot="true"
  >
    <form
      method="POST"
      @submit.prevent="submit"
    >
      <b-field
        :label="$t('password')"
      >
        <b-input
          v-model="password"
          type="password"
          required
          autofocus
        />
      </b-field>
      <b-field
        :label="$t('passwordConfirmation')"
      >
        <b-input
          v-model="passwordConfirmation"
          type="password"
          required
        />
      </b-field>
      <hr>
      <b-field grouped>
        <b-button
          :class="{'is-loading':isLoading}"
          type="is-info"
          native-type="submit"
          class="is-fullwidth"
          icon-left="lock-reset"
        >
          {{ $t('resetPassword') }}
        </b-button>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import axios from 'axios';

export default {
  name: 'PasswordReset',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      password: '',
      passwordConfirmation: '',
    }
  },
  mounted () {
    this.$store.dispatch('logout')
  },
  methods: {
    submit () {
      const { password, passwordConfirmation } = this;

      const baseURL = `//${this.$route.params.host}/api/`
      this.isLoading = true

      axios.post(`${baseURL}auth/reset`, {
        email: this.$route.params.email,
        token: this.$route.params.token,
        password: password,
        password_confirmation: passwordConfirmation
      },{
        headers: {
          'X-Localization': localStorage.getItem('lang') || 'en'
        }
        })
        .then(() => {
          this.password = ''
          this.passwordConfirmation = ''

          this.$buefy.toast.open({
            message: this.$t('resetPasswordSuccess'),
            type: 'is-success',
            queue: false,
            position: 'is-top'
          })

          setTimeout(() => {
            // this.$router.push({ name: 'login'})
            window.document.location = '/login';
          }, 2000);
        })
        .catch(({ response }) => {
          const message = this.$_.flattenDeep(this.$_.values(response.data.errors)).join('<br>')

          this.$buefy.toast.open({
            message,
            type: 'is-danger',
            queue: false,
            position: 'is-top'
          })
        })
        .finally(() => {
          this.isLoading = false
        });
    }
  }
}
</script>
